import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PhotoPrintingPage } from '../../order/photo-printing/photo-printing.page';
import { AlertController } from '@ionic/angular';
import { PhotoBookPage } from '../../order/photo-book/photo-book.page';

@Injectable()
export class CanDeactivatePhotoUpload implements CanDeactivate<PhotoPrintingPage | PhotoBookPage> {
  constructor(
    private alertController: AlertController
  ) {
  }

  canDeactivate(
    component: PhotoPrintingPage | PhotoBookPage,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let canDeactivate = true;
    if (component.imageLoadingArray.length > 0) {
      this.presentAlert();
      canDeactivate = false;
    }
    return canDeactivate;
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Paznja!',
      message: 'Nije moguće napustiti stranicu dok je otpremanje slika u toku',
      buttons: ['OK']
    });

    await alert.present();
  }
}
